






import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PaymentSuccess extends Vue {
  paymentResult: any | null = null;
  encodedUrlPath: any | null = null;

  mounted() {
    // Get the query parameter named 'yourQueryParam'
    const queryResult = this.$route.query.payment;

    if (queryResult === 'success') {
      this.paymentResult = 'Your payment is successful';
    } else if (queryResult === 'failed') {
      this.paymentResult = 'Your payment is failed';
    } else {
      this.paymentResult = 'You cancelled the payment';
    }

    this.encodedUrlPath = this.$route.query.path ?? '/fan/home';

    const decodedUrlPath = this.encodedUrlPath ? decodeURIComponent(this.encodedUrlPath) : '/fan/home';
    console.log('decodedUrlPath', decodedUrlPath);
    // Redirect after 5 seconds
    setTimeout(() => {
      this.$router.push(decodedUrlPath); // Replace '/new-route' with your desired route
    }, 5000);
  }
}
